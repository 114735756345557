@use "mixins";

/* COMMON CLASS */

.hw100 {
    height: 100%;
    width: 100%;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.no-margin-no-padding {
    margin: 0;
    padding: 0;
}

.zindex10 {
    z-index: 10
}

.cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.wh100 {
    width: 100%;
    height: 100%;
}

.display-table {
    display: table;
}

.display-table-cell {
    display: table-cell;
    vertical-align: middle;
}

/* STYLE CONTENT  */

.no-margin-bottom {
    margin-bottom: 0;
}

.overlay-shade {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    @extend .hw100;
    z-index: 2;
}

.background-overly {
    @extend .hw100;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
}

.background-overly-inner {
    @include mixins.crossbrowseropacity (black, 0.3);
}

.relative1 {
    position: relative;
    ;
    z-index: 2;
}

.relative5 {
    position: relative;
    ;
    z-index: 5;
}

.center-col {
    float: none !important;
    margin-left: auto;
    margin-right: auto;
}

.no-margin-bottom {
    margin-bottom: 0;
}
