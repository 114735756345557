@use "variables";

// List Icons
// -------------------------
@use 'sass:math';

.#{variables.$fa-css-prefix}-ul {
  padding-left: 0;
  margin-left: variables.$fa-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{variables.$fa-css-prefix}-li {
  position: absolute;
  left: -(variables.$fa-li-width);
  width: variables.$fa-li-width;
  top: math.div(2em, 14);
  text-align: center;
  &.#{variables.$fa-css-prefix}-lg {
    left: -(variables.$fa-li-width) + math.div(4em, 14);
  }
}
