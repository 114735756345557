@use "../abstracts/vars";
@use "../abstracts/mixins";

.checkout {

    td.AddressInfoTime1 {
        border-right: 1px solid #bbb;
        padding-left: 10px !important;
        padding-right: 20px !important;

        input[type=text] {
            width: 65px;
            display: inline-block;
        }
    }

    td.AddressInfoTime2 {
        padding-left: 20px !important;

        input[type=text] {
            width: 65px;
            display: inline-block;
        }
    }

    label.error {
        display: none;
    }

    fieldset {
        padding: 10px;
        margin-bottom: 10px;

        legend {
            font-weight: 900;
        }
    }

    &__openingtimes {
        margin: 20px 0;
    }

    &__info {
        font-size: smaller;        
    }

    &__addresses {
        margin-top: 10px;

        label {
            cursor: pointer;
            padding-left: 10px;        
        }
    }

    &__address {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        > div {
            padding-right: 5px;
        }

        label {
            //cursor: pointer;
            width: 226px;
            font-weight: 400;
            //padding-left: 10px;
        }

        &--new {
            > div {
                margin: 20px 0;
            }
        }

        select {
            margin-bottom: 10px;
        }
    }

    input[type=search] + #clear {
        position: absolute;
        top: 10px;
        margin-right: 10px;
        right: 14px;        
        content: "\f057";
        cursor: pointer;
      }
}