﻿.basket-mobile {
    table.assortment-table {
        margin: 10px 0;

        td,
        th {
            padding: 3px;
        }
    }

    .form-control {
        width: 40px;
    }

    .product-name {
        font-weight: 700;
    }

    .basket-sizes {
        margin: 10px 0 0 0;
    }

    .basket-line-mobile {
        margin: 10px 0;
    }

    .basket-line-mobile-discount {
        font-size: 12px;
    }

    .basket-line-mobile .btn.delete {
        margin-top: 15px;
        padding: 8px;
    }

    .mobile-image-column {
        width: 75px;
        vertical-align: top;
        text-align: center
    }

    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.cart-summary {
    .total-price-ex-vat {
        overflow: hidden;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        tbody>tr>td {
            padding: 8px 0px;
        }
    }
}

#place-order {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}