input#accountTakeoverSearch {
    float: left;
    width: 300px;
    height: 48px;
    margin-right: 5px;
    font-size: 24px;
}

#accountTakeover {
    &SearchButton {
        cursor: pointer;
        padding-top: 2px;
    }
}

#accountTakeoverSearchButton[disabled] {
    cursor: not-allowed;
}

#takeoverResults table td {
    vertical-align: middle;
}

.search-btn {
    cursor: pointer;
}