
@use "header_desktop";
@use "../../abstracts/vars";


.userMenu > li > a {
    color: vars.$color1;
}

.languageMenu {    
    margin-top: 4px;
    margin-right: 3px;

    .dropdown-menu {
        top: -11px;
        right: 12px;
        padding: 5px;
        border-top: none;
        min-width: 0;

        li {
            line-height: 38px;
            position: relative;
            padding: 0 20px 0 0;

            &:hover {
                background: #eee;
            }

            a {
                display: inline-block;
                padding: 0 15px 0 0;

                &:hover {
                    background: transparent;
                }

                img {
                    position: absolute;
                    right: 0;
                    top: 8px;
                }
            }
        }
    }
}

#header.stuck+.headerOffset {
    padding-top: 50px;
}

.headerOffset {
    padding-top:0;
}

/* Mobile navbar */
@media screen and (max-width: 767px) {
    body {
        padding-top: 0;
    }

    .menuBehindLogo {
        margin-left: 0;
    }

    .navbar-nav {
        padding: 0 vars.$spacing;
    }

    .navbar-brand {
        position: static;
        margin-top: 30px;
    }

    .navbar-brand > img {
        display: none;
    }

    .navbar-top {
        background: vars.$color-white;
    }

    .navbar-brand {
        span.mobile {
            display: block;

            img {
                position: absolute;
                top: 10px;
                left: 10px; 
                height: auto;               
            }
        }
    }

    .headerFocus {
        display: none;
    }

    .navbar-fixed-bottom, .navbar-fixed-top {
        position: relative;
    }

    #header {
        $navHeight: 88px;
        $spacing: 5px;
        background-color: vars.$color-white; // transparent;
        margin-bottom: 0;

        .navbar-collapse {
            background-color: vars.$color-blue;
            min-height: 400px;
        }

        .search-full {
            display: none;

            > .container {
                padding-left: 0;
                padding-right: 0;
            }

            &.active {
                display: block;
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                height: $navHeight;
                width: auto;
                z-index: 101;
                background: vars.$color1;

                .searchInputBox {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: $spacing;
                    bottom: $spacing;
                    width: auto;
                    height: $navHeight - ($spacing*2);

                    .search-input {
                        border: 0;
                        height: $navHeight - ($spacing*2);
                        margin-right: $spacing;
                        max-width: 95%;
                    }

                    .search-btn {
                        color: vars.$color-white;

                        i {
                            font-size: 22px;
                        }
                    }
                }
            }

            .search-close {
                background: none;
                height: $navHeight;

                i {
                    font-size: 32px;
                }
            }
        }
    }
}
