@use "../abstracts/vars";
@use "../abstracts/mixins";
@use "../abstracts/utilities";

@use "../base/fontawesome/font-awesome";

body.modal-open-noscroll {
  margin-right: 0 !important;
  overflow: hidden;
}

.modal-open-noscroll .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 0 !important;
}

// Sidebar Nav

.slide-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/*------------------------------------------------------------------
 slider style
 -------------------------------------------------------------------*/

.slider-hero-wrapper {
  height: 100%;

  /* Specify Swiper's Size: */
  .swiper-container,
  .swiper-slide {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .slider-caption {
    max-width: 560px;
    color: vars.$color-white;
  }

  .container {
    opacity: 0;
    @include mixins.transition(opacity, 0.25s, ease, 0.45s);
  }

  .swiper-slide-active {
    .container {
      opacity: 1;
    }
  }
}

.slider-hero-bg {
  @extend .cover;
}

.slider-hero {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  .arrow-left {
    background: url(/assets/img/arrows.png) no-repeat left top;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -15px;
    width: 17px;
    height: 30px;
    z-index: 999;
  }

  .arrow-right {
    background: url(/assets/img/arrows.png) no-repeat left bottom;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    width: 17px;
    height: 30px;
    z-index: 999;
  }

  @include mixins.respond-to(xsOnly) {

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }

  .slider-hero-content {
    cursor: move;

    &.has-overly-shade {
      >.hw100 {
        @include mixins.crossbrowseropacity(black, .3);
      }
    }

  }

  .container {
    position: relative;
    z-index: 2;
  }

  .pagination {
    position: absolute;
    left: 0;
    text-align: center;
    bottom: 5px;
    width: 100%;
    z-index: 700;
  }

  .swiper-pagination-switch {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #999;
    box-shadow: 0px 1px 2px #555 inset;
    margin: 0 3px;
    cursor: pointer;
  }

  .swiper-active-switch {
    background: vars.$color-white;
  }

  .swiper-pagination-bullet {
    background: none;
    border: 1px solid vars.$color-white;
  }

  .swiper-pagination-bullet-active {
    background: vars.$color-white;
    opacity: 1;
  }
}

.slider-hero-caption {
  max-width: 40%;
  position: relative;

  &.max-60 {
    max-width: 60%;
  }

  .slide-caption-subtitle {
    font-family: oswald, sans-serif;

    font-weight: normal;

    &.top {
      font-size: 22px;
      letter-spacing: 4px;

    }

    &.bottom {
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: lighter;
      margin-bottom: 40px;

      @include mixins.respond-to(xsOnly) {
        margin-bottom: 0;
      }
    }
  }

  .slide-caption-title {

    color: vars.$color-white;
    letter-spacing: -0.5px;
    font-size: 56px;
    line-height: 50px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 20px;
    @include mixins.transition(font 0.5s ease 0s);

    span {
      font-weight: lighter;
    }

    &.sale {
      font-size: vars.$baseFontSize*6;
      line-height: vars.$baseFontSize*6;
    }

    @include mixins.respond-to(xsOnly) {
      &.sale {
        font-size: vars.$baseFontSize*3;
        line-height: vars.$baseFontSize*3;
      }
    }

    &.slide-caption-title-2 {
      font-family: oswald, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-weight: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

  }

  h3.slide-caption-title-3 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;

    a {
      &:hover {
        opacity: 0.7;
      }
    }

    @include mixins.respond-to(xsOnly) {
      font-size: 20px;
    }
  }

  p {
    color: #efefef;
    font-size: 16px;
    letter-spacing: 0.7px;
    padding-bottom: 20px;
    @include mixins.transition(font 0.5s ease 0s);

  }

  .btn-default {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.08);
    border-radius: 2px;
    border-width: 1px;
    color: vars.$color-white;
    font-family: Oswald;
    font-size: 12px;
    font-weight: lighter;
    letter-spacing: 1px;
    padding: 15px 30px;
    text-transform: uppercase;
  }

  .btn {
    font-size: 13px;
  }

  &.has-dark-bg {

    *,
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: vars.$color-white;
    }

  }

  &.has-light-bg {

    *,
    h1,
    h2,
    h3,
    h5,
    h5,
    p {
      color: #3a3a3a;
    }
  }
}

// NAVBAR HERO
.navbar-default.navbar-hero {
  background-color: vars.$color-white;
  border-color: vars.$color-white;
  @extend .transition-all;

  .navbar-brand {
    float: left;
    font-size: 18px;
    height: 50px;
    line-height: 20px;
    padding: 15px;
    padding-top: 12px;
  }

  .navbar-nav {
    > li {
      > a {
        font-weight: normal;
        letter-spacing: 2px;
        color: #333;
        letter-spacing: 0.1em;
        font-family: "oswald", sans-serif;
        font-size: 11px;
        text-transform: uppercase;
        @include mixins.transition(all 400ms ease-in-out 0s);

        i {
          font-size: 14px;
        }
      }

      > ul {
        > li {

          a {
            font-size: 12px;
          }
        }
      }
    }
  }

  @include mixins.respond-to(desktopOnly) {
    @include mixins.transition(all 400ms ease-in-out 0s);

    &.sticky {
      @include mixins.crossbrowseropacity(vars.$color-white, 0.85);
    }

    .navbar-nav {
      > li {
        > a {
          font-weight: normal;
          letter-spacing: 2px;
          color: #333;
          letter-spacing: 0.1em;
          font-family: "oswald", sans-serif;
          font-size: 11px;
          text-transform: uppercase;
          @include mixins.transition(all 400ms ease-in-out 0s);
        }

        > .hero-submenu {
          left: 0;
          list-style-type: none;
          overflow: hidden;
          position: absolute;
          right: 0;
          width: 100%;
          display: block;
          z-index: -10;
          opacity: 0;
          visibility: hidden;
          transform: scale(0.99);
          transition: all 300ms ease-in-out 0s;
          top: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          color: #777;

          .megamenu-content {
            @include mixins.transition(all 300ms ease-in-out 0.25s);
            opacity: 0;
            transform: scale(1);

            ul > li {
              border-bottom: 0 none;

              > a {
                color: #777;
              }
            }

          }

          .menu-title {
            border-bottom: 1px solid #ddd;
            color: #666;
            cursor: pointer;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.5px;
            margin-bottom: 10px;
            max-height: 12px;
            padding: 0 40px 40px 0;
            position: relative;
            text-transform: uppercase;
            @include mixins.transition(all 0.25s ease-in);
          }
        }

        &:hover,
        &.open {

          >.hero-submenu {
            height: auto;

            opacity: 1;
            z-index: 1;
            transform: scale(1);
            visibility: visible;
            -webkit-backface-visibility: hidden;
            backface-visibility: visible;

            .megamenu-content {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        &.open,
        &:hover {
          >ul.dropdown-menu {
            display: block;
            visibility: visible;
            opacity: 1;
            min-width: 240px;
            z-index: 1;

            >li {
              >a {
                box-sizing: border-box;
                display: block;
                height: auto;
                font-weight: 600;
                line-height: 34px;
                font-size: 12px;
                color: #777;
                padding: 5px 10px;
                @include mixins.transition(color 400ms ease-out);
                text-decoration: none;

                &:hover {
                  color: vars.$color-black;
                }
              }
            }
          }
        }
      }
    }
  }

  // desktop only

  .nav>li.active,
  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav>li:hover>a {
    background: none;
  }

  @include mixins.respond-to(xsOnly) {

    .miniCartProduct,
    .miniCartProduct a,
    .miniCartProduct p,
    .miniCartProduct .size,
    .miniCartProduct .price,
    .dropdown-header,
    .miniCartFooterInMobile .subtotal,
    .megamenu-content ul li a,
    .megamenu-content ul li p,
    .megamenu-content ul li,
    .navbar-nav .dropdown a,
    .navbar-nav .dropdown {
      color: #333;
    }

    .search-full {
      height: 57px;

      .search-close {
        height: 100%;

      }
    }
   
    .dropdown.open {
      .dropdown-toggle {
        background: #ddd !important;
      }

    }

    .colorWhite,
    .colorWhite i {
      color: #333 !important;
    }

    .navbar-toggle {
      background: none !important;
      border: 0;

      .icon-bar {
        border: 2px solid #333;
      }
    }

    .search-box {
      float: right;
      height: 100%;
      width: 30px;
    }

  }
}

.overlay-shade {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.has-equal-height-child {
  .product-item-inner {
    height: 100%;
  }
}

.product-item-inner {
  background: vars.$color-white;

  .product-btn-box {

    border: 0 none;
    display: block;
    height: auto;
    padding: 5px 0 10px;
    width: 100%;
  }

  .product-color {
    text-align: center;
    max-width: 90%;
    display: block;
    margin: 0 auto;

    a {
      display: inline-block;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: solid 1px transparent;

      &.active {
        border-color: #858585;
      }
    }
  }

  .imageHover.hasCart {
    .product-btn-box {
      padding: 0;
      position: absolute;
      bottom: 0;
      z-index: 10;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%);
      @include mixins.transition(transform, 0.2s, ease-in-out);

      .btn-product-flat {
        padding: 12px 15px;
        @include mixins.crossbrowseropacity(#3a3a3a, .9);

        &:hover {
          border-top: 1px solid #ddd;
          @include mixins.crossbrowseropacity(vars.$color-white, .9);
        }
      }
    }
  }

  &:hover {
    border-color: vars.$line-color;

    .imageHover.hasCart {
      .product-btn-box {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }
}

.owl-carousel {
  .product-item.item-flat {
    margin: 0 15px;

  }
}

.featuredImageLook3.flat {

  .inner {
    border: solid 1px #ddd;
    -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
    transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;

    .image-block img {
      -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
      transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
    }
  }
}

@include mixins.respond-to(xsOnly) {
  .item-flat {
    margin-bottom: 15px;
  }
}

.wishlist-flat {
  background-color: #c8c8c8;
  color: vars.$color-white;
  border-color: #c8c8c8;
  @include mixins.transition(opacity 0.25s ease-in);

  &:hover {
    background-color: #222 !important;
    border-color: #222;
    color: vars.$color-white;
  }
}

.prod-details {

  border-top: 1px solid #ddd;
  height: auto;
  margin: 0 auto auto;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;

  p.title,
  p a {
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p.product-flat-info {
    color: #acb1b4;
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: 300;
    margin-bottom: 5px;

    span.product-current-price {
      color: #1e1e1e;
    }
  }

}

// FULL SEARCH

.search-overly-mask {
  background-color: #EDF2F1;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  @include mixins.transition(opacity, 400ms, ease-out);
  visibility: hidden;
  width: 100%;
  z-index: 1000;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

#search-overly {

  .search-close {
    background: none;
    color: #333;
    position: absolute;
    right: 20px;
    margin-top: 60px;

    @include mixins.respond-to(xsOnly) {
      right: 10px;
    }
  }

  .container {

    margin-top: 90px;

    .controls {
      position: relative;
    }

    .control-label {
      letter-spacing: 1px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .form-control {
      border: none;
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border-bottom: solid 4px #000;
      box-shadow: none;
      box-sizing: border-box;
      color: #000;
      font-family: sans-serif;
      height: auto;
      margin: 0;
      outline: medium none;
      text-align: left;
      box-shadow: none;
      border-radius: 0;
      font-weight: bold;
      width: 100%;
      font-size: 68px;
      line-height: 1.2;
      padding: 15px 60px 15px 0;

      &::-moz-placeholder {
        color: #000;
      }

      &:focus {
        &::-moz-placeholder {
          color: #666;
        }
      }

      @include mixins.respond-to(xsOnly) {
        font-size: 32px;
      }
    }

    .input-group-btn {
      position: absolute;
      right: 0;
      top: 26px;
      width: auto;
    }

    .btn {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border: medium none;
      color: #000;
      font-size: 32px;

      &:hover {
        color: #666;
      }

      @include mixins.respond-to(xsOnly) {
        font-size: 18px;
      }
    }
  }
}

// Responsive Issue

@include mixins.respond-to(xsOnly) {
  .hide-xs {
    display: none !important;
  }

  .slider-hero-caption {
    max-width: 90% !important;

    .slide-caption-title {
      font-size: 32px;
      line-height: 32px;
      padding-bottom: 10px;
    }

    p {
      padding-bottom: 10px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }

  .btn-stroke {
    letter-spacing: 1px;
    min-width: 160px;
    padding: 10px 15px;
  }
}

// MAIN CONTAINER

.section-hero {
  padding: 50px 0;

}

.section-gray {
  background: vars.$gray;
  border-bottom: 1px solid vars.$line-color;
  // border-top: 1px solid $line-color;
}

.hero-section-header {
  margin-bottom: 25px;
  text-align: center;

  .hero-section-title {
    font-family: oswald, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    color: #1e1e1e;

  }

  .hero-section-sub-text {
    color: vars.$text-color;
  }
}

.inner {
  .img-title {
    -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
    transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
    opacity: 1;
  }

  .img-block {
    max-height: 100%;
    overflow: hidden;

    img {
      transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
    }

  }

  &:hover {
    .img-title {
      opacity: 0;
    }

    .img-block {
      img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0s;
        transition: transform 0.15s cubic-bezier(0, 0, 0.2, 1) 0s;
      }
    }

    .box-content-overly-white {
      &.flat {
        .btn-dark {
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .box-content-overly-white {
      &.flat {
        .submini {
          width: 70%;
        }
      }
    }
  }

  .box-content-overly-white {
    &.flat {
      h1 {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .submini {
        width: 0;
        @include mixins.transition(all, 0.45s, linear, 0.1s);
      }

      .box-text-cell-inner {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
        transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
        opacity: 0;
      }

    }

  }

  &:hover {

    .box-content-overly-white {
      &.flat {
        .box-text-cell-inner {
          -webkit-transform: translateY(0px);
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  }
}

// inner

.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;

}

.block-explore {
  padding: 0 10px;

  .inner {
    display: block;
    margin-bottom: 20px;
    position: relative;

    .overly {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      display: block;
      -webkit-transition: background 0s ease 0s, all 0.25s ease 0s;
      transition: background 0s ease 0s, all 0.25s ease 0s;

      .explore-title {
        color: white;
        display: block;
        font-family: Oswald;
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 3px;
        padding: 0 10px;
        position: absolute;
        text-align: center;
        text-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        top: 40%;
        top: calc(#{"50% - 11px"});
        -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
        transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
        opacity: 1;
        width: 100%;
        z-index: 100;

        &::after {
          color: white;
          content: "\e258";
          display: inline-block;
          font-family: "Glyphicons Halflings";
          font-size: 0.8em;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          line-height: 1;
          position: static;
          text-transform: none;
          width: 0;
          opacity: 0;
          overflow: hidden;
          -webkit-transition: all 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
          transition: all 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
        }
      }

    }

    &:hover {
      .explore-title {
        &::after {
          width: 20px;
          opacity: 1;
        }
      }

      .overly {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        @include mixins.crossbrowseropacity(black, .3);
        z-index: 1000;
      }

      .img-title {
        opacity: 0.95 !important;
        visibility: visible !important;

      }
    }

  }
}

.section-hero-parallax {
  background-position: center center;

  .hero-parallax-content {
    padding: 100px 0;
    text-align: center;

    h3 {
      font-family: oswald, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-size: 70px;
      line-height: 90px;
      text-transform: uppercase;
      color: vars.$color-white;

      @include mixins.respond-to(xsOnly) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .overly-shade {
    @include mixins.crossbrowseropacity(black, 0.2);
  }
}

// FOOTER

.footer-content {
  // background-color: #f9f7f3;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: 40px 0;
  color: #333;

  @include mixins.respond-to(xsOnly) {
    padding: 20px 0;
  }
}

.footer-title {
  color: vars.$color-black;
  font-family: "oswald", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 15px;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-address li {
  line-height: 14px;
  list-style: outside none none;
  margin-bottom: 5px;
}

.footer-logo {
  padding-top: 10%;

  a {
    display: block;
  }
}

.copy-info {
  display: block;
  font-size: 11px;
  margin-top: 10px;
  padding-left: 0;
}

.paymanet-method-logo {
  border-top: 1px solid vars.$gray-light;
  border-bottom: 1px solid vars.$gray-light;
  color: vars.$text-color-extra-liter;
  font-size: 11px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  margin-top: 30px;
  padding: 20px 0;
  text-transform: uppercase;

  img {
    display: inline-block;
    margin: 0 5px;
    height: 30px;
  }
}

.footer-nav-inline {
  li {
    a {
      color: #333;
      font-size: 16px;
    }
  }
}

.hero-subscribe {
  margin-top: 30px;

  #newsletter-signup {
    .form-control {
      height: 36px;
      border-radius: 0;
    }

    .btn {
      border-radius: 0;
      background: #3a3a3a;
    }

  }
}

@include mixins.respond-to(xsOnly) {
  .footer-title {
    margin-bottom: 0;
    margin-top: 15px;
  }

  .paymanet-method-logo img {
    display: inline-block;
    height: 20px;
    margin: 0 2px;
  }
}

.isios.product-story {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: top center !important;
}

@include mixins.respond-to(xsOnly) {
  .search-trigger {
    color: #333;
  }
}

// V-7.2

@include mixins.respond-to(desktopOnly) {
  .navbar-hero-center {
    .navbar-nav.nav-center {
      position: fixed;
      top: 0;
      left: 50%;
      z-index: 99;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

/* Contact Us */
#divContactFormSent {
  display: none;
}

#divBecomeClientFormSent {
  display: none;
}