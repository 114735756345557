@use "sass:color";
@use "../../abstracts/vars";
@use "../../abstracts/utilities";

@media screen and (min-width: 768px) {
    #header {
        margin-bottom: 0;
        /*position: static;*/
        position: relative; /* override fixed-top bootstrap setting*/
        border: 0;
        /*height: $headerHeight;*/
        > .navbar-top {
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;
            background: vars.$color-white;
            height: vars.$headerTopHeight;

            .userMenu {
                a {
                    padding: 0 10px;
                }
            }
        }

        .navbar-right {
            -moz-transition: top linear 0.4s;
            -o-transition: top linear 0.4s;
            -webkit-transition: top linear 0.4s;
            transition: top linear 0.4s;
            position: relative;
            top: -200px;

            > .cartMenu, > .search-box, > .userMenu {
                -moz-transition: opacity linear 0.5s 0.2s;
                -o-transition: opacity linear 0.5s 0.2s;
                -webkit-transition: opacity linear 0.5s 0.2s;
                transition: opacity linear 0.5s 0.2s;
                float: left;
                opacity: 0;

                a, button {
                    -moz-transition: all .2s ease-in;
                    -o-transition: all .2s ease-in;
                    -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;
                    display: block;
                    color: vars.$color-white;
                    padding: 15px 10px;
                    font-size: 14px;                    
                    margin: 0;
                    border-radius: 0;

                    &:hover {
                        background: color.adjust(vars.$color1, $lightness: -10%);
                    }
                }
            }
        }

        .navbar-nav {
            display: flex;
            flex-direction: row;

            > li {
                display: flex;
                flex-direction: revert;
                align-content: center;
                flex: 1 0 auto;

                > a {
                    color: vars.$color-white;
                    padding-left: 2px;
                    padding-right: 2px;
                    font-size: 11px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-flow: wrap;
                    flex: 1 0 auto;

                    &:hover, &:focus {
                        background: vars.$color-blue-dark;
                    }                    
                }
            }

            h3 {
                display: none;
            }
        }

        .navbar-collapse.collapse {
            height: auto;
            overflow: hidden;
            display: flex !important;
            flex-direction: row !important;
        }
    }

    .unstuckNav {
        #header {
            .search-full {
                $searchHeight: 50px;
                $searchWidth: 400px;
                $searchButtonWidth: 50px;
                display: block !important;
                background: none;
                position: absolute;
                left: auto;
                right: 20px;
                top: 40px;
                height: $searchHeight;
                width: $searchWidth;

                .container {
                    padding: 0;
                    width: auto;
                }

                // .searchInputBox {
                //     height: $searchHeight;
                //     width: $searchWidth;
                //     max-width: 100%;
                //     margin: 0;
                //     padding: 0;
                // }

                .search-input {
                    color: #666;
                    background: vars.$color-white;
                    border: 1px solid vars.$gray-light;
                    max-width: 100%;
                    width: $searchWidth - $searchButtonWidth;
                    height: $searchHeight;
                    float: left;
                    margin: 0;
                    font-size: 1.5em;
                    text-align: left;

                    &:hover, &:focus {
                        border-color: vars.$color-blue-light;

                        + .search-btn {
                            background: vars.$color-blue-light;
                        }
                    }
                    /* do not group these rules */
                    &::-webkit-input-placeholder {
                        color: vars.$gray-light2;
                    }

                    &:-moz-placeholder {
                        /* FF 4-18 */
                        color: vars.$gray-light2;
                    }

                    &::-moz-placeholder {
                        /* FF 19+ */
                        color: vars.$gray-light2;
                    }

                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: vars.$gray-light2;
                    }
                }

                .search-btn {
                    color: vars.$color-white;
                    background: vars.$gray-light;
                    display: inline-block;
                    //float: left;
                    margin: 0;
                    height: $searchHeight;
                    width: $searchButtonWidth;

                    &:hover {
                        background: vars.$color-blue;
                    }
                }
            }

            .search-close {
                display: none;
            }

            .navbar-right {
                top: -200px;

                > .cartMenu, > .search-box {
                    opacity: 0;
                }
            }

            .search-input[placeholder] {
                color: #666;
            }
        }
    }

    .stuckNav {
        $spacing: 5px;
        /*padding-top: $headerHeight;*/

        #header {

            position: fixed;
            height: 0px;
            
            .navbar-top {
                margin-top: -(vars.$headerTopHeight);
            }

            .navbar-right {
                top: 0;

                > .cartMenu, > .search-box, > .userMenu {
                    opacity: 1;
                }
            }

            .search-full {
                display: none;

                &.active {
                    display: block;
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    height: 50px;
                    width: auto;
                    z-index: 101;
                    background: vars.$color1;

                    .searchInputBox {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: $spacing;
                        bottom: $spacing;
                        width: auto;
                        height: vars.$navHeight - ($spacing*2);

                        .search-input {
                            border: 0;
                            height: 40px;
                        }

                        .search-btn {
                            color: vars.$color-white;
                        }
                    }
                }

                .search-close {
                    background: none;
                    height: vars.$navHeight;
                }
            }
        }
    }

    .navbar-right {
        margin-left: 0;
    }

    .navbar-brand {
        // height: auto;        
        padding: 15px 10px;

        > span.mobile {
            display: none;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    // .searchInputBox {
    //     max-width: auto;
    //     width: 500px;
    // }

    .searchInputBox .search-btn {
        padding-left: 10px;
        font-size: 2em;
    }
}

@media (min-width: 992px) {
    #header {
        .navbar-nav {
            > li {
                > a {
                    padding-left: 5px;
                    padding-right: 5px;
                    font-size: 12px;
                    color: vars.$color-white;
                }
            }
        }

        .headerFocus {
            li.secondary {
                display: inline-block;
            }
        }
    }
}
 
@media (min-width: 1200px) {    
    #header {
        .navbar-nav {
            max-width: 1030px;

            > li {
                > a {
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 13px;
                    color: vars.$color-white;
                }
            }
        }

        .headerFocus {
            li.secondary {
                display: inline-block;
            }
        }
    }
}

/* Overrides */
.caret {
    display: flex;
    align-self: center;
    margin-left: 4px;
}

.nav > li > a {
    display: flex;
}
