@use "../../abstracts/vars";

.orderStep {

    li {
        display: inline-block;
        /* float: left; */
        height: auto;
        margin: 0;
        min-height: 55px;
        padding: 0;
        width: 25%;

        .active a {
            color: vars.$color-white;
            position: relative;
        }
    }

    li.active a:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-width: 10px;
        margin-left: -10px;
    }

    li a {
        background: #EFF0F2;
        display: inline-block;
        font-size: 14px;
        height: 100%;
        line-height: normal;
        padding: 20px 0 0;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        text-transform: uppercase;
        font-size: 13px;
    }

    li.active a i {
        background: rgba(0, 0, 0, 0.1);
        color: vars.$color-white;
    }

    li a i {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 0;
        display: block;
        font-size: 20px;
        height: auto;
        left: 0;
        line-height: 40px;
        margin-top: -20px;
        padding: 0;
        text-align: center;
        width: auto;
        float: none !important;
    }

    .orderStep li a span {
        display: block;
        padding: 5px 0;

        @media screen and (max-width: 580px) {
            font-size: 11px;
        }
    }
}