@use "sass:color";

@use "_vars";

@mixin respond-to($media) {
    @if $media==xsOnly {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }
    @if $media==desktopOnly {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
    @if $media==max600 {
        @media only screen and (min-width:0) and (max-width: 600px) {
            @content;
        }
    }
    @if $media==max400 {
        @media only screen and (min-width:0) and (max-width: 400px) {
            @content;
        }
    }
    
    @if $media==max480 {
      @media only screen and (min-width:0) and (max-width: 480px) {
        @content;
      }
    }
  
    @if $media==mdOnly {
        @media only screen and (min-width:992px) and (max-width: 1199px) {
            @content;
        }
    }
    @if $media==smOnly {
        @media only screen and (min-width:768px) and (max-width: 991px) {
            @content;
        }
    }     
}

// Border Radius
@mixin border-radius($radius: 5px){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }
  
  /* Implementation */
  #div {
    @include border-radius(20px);
  }
  
  @mixin border-radius-custom($topleft: 5px, $topright: 5px, $bottomleft: 5px, $bottomright: 5px){
    -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
    -moz-border-radius: $topleft $topright $bottomright $bottomleft;
    border-radius: $topleft $topright $bottomright $bottomleft;
  }
  
  /* Implementation */
  #div {
    @include border-radius-custom(20px, 20px, 0px, 0px);
  }
  
  // BOX SHADOW
  @mixin box-shadow($x: 0px, $y: 3px, $blur: 5px, $alpha: 0.5){
    -webkit-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
  }
  
  /* Implementation */
  #div {
    @include box-shadow(5px, 5px, 6px, 0.3);
  }
  
  // TRANSITION
  @mixin transition($prop: all, $time: 1s, $ease: linear, $delay: 0s){
    -webkit-transition: $prop $time $ease $delay;
    -moz-transition: $prop $time $ease $delay;
    -o-transition: $prop $time $ease $delay;
    -ms-transition: $prop $time $ease $delay;
    transition: $prop $time $ease $delay;
  }
  
  /* Implementation */
  .transition-all {
    @include transition(all, 0.25s, linear);
  }
  
  // TRANSFORM
  @mixin transform($rotate: 90deg, $scale: 1, $skew: 1deg, $translate: 10px){
    -webkit-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
    -moz-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
    -o-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
    -ms-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
    transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  }
  
  /* Implementation */
  #div {
    @include transform(5deg, 0.5, 1deg, 0px);
    @include transform(5deg, 0.5, 1deg, 0px);
  }
  
  // GRADIENT
  @mixin gradient($origin: to left, $start: vars.$color-white, $stop: vars.$color-black){
    background-color: $start;
    background-image: -webkit-linear-gradient($origin, $start, $stop);
    background-image: -moz-linear-gradient($origin, $start, $stop);
    background-image: -o-linear-gradient($origin, $start, $stop);
    background-image: -ms-linear-gradient($origin, $start, $stop);
    background-image: linear-gradient($origin, $start, $stop);
  }
  
  // GRADIENT start with 0
  @mixin gradient0($origin: to left, $start: vars.$color-white, $stop: vars.$color-black){
    background-color: transparent;
    background-image: -webkit-linear-gradient($origin, $start, $stop);
    background-image: -moz-linear-gradient($origin, $start, $stop);
    background-image: -o-linear-gradient($origin, $start, $stop);
    background-image: -ms-linear-gradient($origin, $start, $stop);
    background-image: linear-gradient($origin, $start, $stop);
  }
  
  /* gradient Implementation */
  #div {
    @include gradient(to left, #663333, #333333);
  }
  
  // QUICK GRADIENT
  @mixin quick-gradient($origin: to left, $alpha: 0.2){
    background-image: -webkit-linear-gradient($origin, rgba(0, 0, 0, 0.0), rgba(0, 0, 0,$alpha));
    background-image: -moz-linear-gradient($origin, rgba(0, 0, 0, 0.0), rgba(0, 0, 0,$alpha));
    background-image: -o-linear-gradient($origin, rgba(0, 0, 0, 0.0), rgba(0, 0, 0,$alpha));
    background-image: -ms-linear-gradient($origin, rgba(0, 0, 0, 0.0), rgba(0, 0, 0,$alpha));
    background-image: linear-gradient($origin, rgba(0, 0, 0, 0.0), rgba(0, 0, 0,$alpha));
  }
  
  /* Implementation */
  #div {
    background-color: #BADA55;
    @include quick-gradient(to top, 0.2);
  }
  
  // Background RGBA
  @mixin crossbrowseropacity($color, $alpha){
    $rgba: rgba(color.channel($color, "red", $space: rgb), color.channel($color, "green", $space: rgb), color.channel($color, "blue", $space: rgb), $alpha);
    $argb: argb($rgba);
    background-color: $color;
    background-color: $rgba;
    -ms-filter: #{"progid:DXImageTransform.Microsoft.gradient(startColorstr=${argb}, endColorstr=${argb})"};
    filter: #{"progid:DXImageTransform.Microsoft.gradient(startColorstr=${argb}, endColorstr=${argb})"};
  }
  /* Implementation */
  #div {
    @include crossbrowseropacity (red, 0.2)
  }
    
//   @mixin responsive($maxWidth; $rules){
//       @media only screen and (max-width: $maxWidth) {
//           $rules();
//       }
//   }