﻿@use "../../abstracts/vars";
@use "../../abstracts/mixins";
@use "../../abstracts/utilities";
@use "../products/images";

/* start from product details 5 less */
.product-details-info {

    .panel-heading .panel-title a:after {
        font-family: 'Glyphicons Halflings';
        content: "\e114";
        float: right;
        color: grey;
    }

    .panel-heading .panel-title a.collapsed:after {
        content: "\e080";
        /* adjust as needed, taken from bootstrap.css */
    }
}

nav.slider-nav a {
    position: absolute;
    top: 50%;
    display: block;
    outline: none;
    text-align: left;
    z-index: 1000;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    svg {
        display: block;
        margin: 0 auto;
        padding: 0;
    }
}

nav.slider-nav a.prev {
    left: 0;
}

nav.slider-nav a.next {
    right: 0;
}

.color-10 {
    background: #f3cf3f;
}

.nav-narrow-svg {

    a.next::before,
    a.next .icon-wrap::before {
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    a.next::after,
    a.next .icon-wrap::after {
        -webkit-transform: translateX(-50%) rotate(45deg);
        transform: translateX(-50%) rotate(45deg);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }

    a.next:hover::before,
    a.next:hover .icon-wrap::before {
        -webkit-transform: translateX(-50%) rotate(125deg);
        transform: translateX(-50%) rotate(125deg);
    }

    a.next:hover::after,
    a.next:hover .icon-wrap::after {
        -webkit-transform: translateX(-50%) rotate(55deg);
        transform: translateX(-50%) rotate(55deg);
    }

    a {
        width: 100px;
        height: 100px;

        @include mixins.respond-to(xsOnly) {
            width: 30px;
            height: 30px;
        }
    }

    .icon-wrap {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    a::before {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 50%;
        background: #566475;
        content: '';
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        top: 50%;
        -webkit-transform: translateX(-50%) rotate(-135deg);
        transform: translateX(-50%) rotate(-135deg);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    a::after {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 50%;
        background: #566475;
        content: '';
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        top: 50%;
        -webkit-transform: translateX(-50%) rotate(-45deg);
        transform: translateX(-50%) rotate(-45deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    .icon-wrap::before {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 50%;
        height: 0;
        background: #566475;
        background: #ffffff;
        content: '';
        -webkit-transition: -webkit-transform 0.3s;
        -webkit-transition: height 0.3s, -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: height 0.3s, transform 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: 100;
        top: 50%;
        -webkit-transform: translateX(-50%) rotate(-135deg);
        transform: translateX(-50%) rotate(-135deg);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    .icon-wrap::after {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 50%;
        height: 0;
        background: #566475;
        background: #ffffff;
        content: '';
        -webkit-transition: -webkit-transform 0.3s;
        -webkit-transition: height 0.3s, -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: height 0.3s, transform 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: 100;
        top: 50%;
        -webkit-transform: translateX(-50%) rotate(-45deg);
        transform: translateX(-50%) rotate(-45deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    a:hover .icon-wrap::before {
        height: 50%;
        -webkit-transform: translateX(-50%) rotate(-125deg);
        transform: translateX(-50%) rotate(-125deg);
    }

    a:hover .icon-wrap::after {
        height: 50%;
        -webkit-transform: translateX(-50%) rotate(-55deg);
        transform: translateX(-50%) rotate(-55deg);
    }

    a:hover::before {
        -webkit-transform: translateX(-50%) rotate(-125deg);
        transform: translateX(-50%) rotate(-125deg);
    }

    a:hover::after {
        -webkit-transform: translateX(-50%) rotate(-55deg);
        transform: translateX(-50%) rotate(-55deg);
    }
}

.nav-narrow-svg.has-white-bg {

    .icon-wrap::before,
    .icon-wrap::after {
        z-index: 100;
        height: 0;
        background: #cccccc;
        -webkit-transition: height 0.3s, -webkit-transform 0.3s;
        transition: height 0.3s, transform 0.3s;
    }

    a {
        width: 70px;
        height: 70px;

        @include mixins.respond-to(xsOnly) {
            width: 30px;
            height: 30px;
        }
    }
}

.product-images-carousel-wrapper {
    .owl-theme .owl-controls {
        bottom: 20px;
        margin-top: 0;
        position: absolute;
        width: 100%;

        @include mixins.respond-to(xsOnly) {
            position: relative;
        }
    }

    .owl-theme .owl-controls .owl-page span {
        box-shadow: none;
    }

    .owl-theme .owl-controls .owl-page span {
        background: rgba(0, 0, 0, 0) none;
        border: 1px solid;
    }

    .owl-pagination {
        display: inline-block;
        margin: 0 auto;
        text-align: center;
    }

    .product-carousel-item-img {
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;

        @include mixins.respond-to(xsOnly) {
            max-width: 100%;
            width: 90%;
            display: block;
        }

    }

}

.product-details-product {

    &-title {
        color: #303030;
        font-size: 30px;
        letter-spacing: -0.5px;
        line-height: 1.1;
        margin: 0 0 10px;
        overflow: hidden;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: normal;
        clear: both;

        @include mixins.respond-to(xsOnly) {
            font-size: 22px;
        }
    }

    &-title-code {
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
    }

    &-brand__link {
        text-decoration: underline;
        transition: color .2s ease;

        &:hover {
            color: vars.$color1;
        }
    }
}

.productPrice {
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    overflow-x: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-details-info-wrapper {

    .price-sales,
    .price-standard,
    .price-tag {
        font-family: oswald;
        font-size: 18px;
        font-weight: normal;
    }

    .price-standard,
    .price-tag {
        font-size: 14px;
    }
}

.price-tag {
    color: #999999;
}

.row-filter {
    margin-bottom: 10px;
    margin-top: 40px;

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 100%;
    }

    .select2-container .select2-selection--single {
        border-radius: 0;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: auto;
        padding: 10px;
        border: 1px solid #dddddd;
    }
}

.row-cart-actions {
    .btn {
        margin-bottom: 10px;
        border: solid 1px transparent;

        &:hover {
            border: solid 1px #303030;
        }
    }
}

.product-details-product-color {
    border-top: 1px solid #dddddd;
    margin: 15px 0;
    padding-top: 15px;

    .selected-color strong {
        text-transform: uppercase;
    }
}

.swatches-rounded {

    .selected,
    li:hover {
        border: 0;
    }

    a {
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid #aaaaaa;
        border-radius: 50%;
        display: block;
        height: 25px;
        text-align: center;
        transition: all 0.25s ease 0s;
        width: 25px;
        box-shadow: none;
    }

    li {
        display: inline-block;
        list-style: outside none none;
        margin: 2px 1px;
        vertical-align: top;
        border: 0;

        >a::after {
            content: "\f00c";
            font-family: fontawesome;
            font-size: 0;
            font-weight: lighter;
            color: #fff;
            transition: font-size 0.25s ease 0.1s;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }

        &.selected>a::after {
            font-size: 75%;
        }
    }
}

.color-value {
    color: #999999;
    padding-left: 10px;
}

// PANEL
.panel {
    &.panel-blank {
        border-bottom: 0 none;
        border-radius: 0;
        box-shadow: none;
        background: none;
        margin-top: 0 !important;

        .panel-heading {
            border-bottom: solid 1px #dddddd;
            padding: 12px 0 12px 1px;

            .panel-title {
                //color: #34495e;
                font-family: oswald;
                font-size: 14px;
                position: relative;
                padding: 0;
                cursor: pointer;
                // color: #808080;
                letter-spacing: 1px;
            }
        }

        .panel-body {
            border: 0 !important;
        }
    }

    &.panel-default {
        li.active {
            a {
                font-weight: bold;
            }
        }
    }
}

.product-details-info {
    border-top: 1px solid #dddddd;
    margin-top: 40px;

    #accordion {
        .panel {
            .collapse {
                border-bottom: 1px solid transparent;

                &.in {
                    border-bottom: 1px solid #dddddd;
                }
            }

            &:first-child {}
        }
    }
}

// .product-details-bottom-bar {
//     background: #f3f4f4;
//     border-bottom: 1px solid $line-color;    
// }

.list-border-inline,
.social-inline {
    .count {
        display: block;
    }

    li {
        float: left;
        padding: 0;

        &:first-child {
            a {
                border-left: solid 1px #ddd !important;
            }
        }

        &.hasShareCount {
            @include mixins.respond-to(max600) {
                display: none;
            }

            >span {
                display: block;
                font-size: 12px;
                font-weight: 600;
                height: 51px;
                line-height: 1;
                padding: 15px;
                text-transform: uppercase;
                border-left: solid 1px #ddd !important;
                border-right: solid 1px #ddd !important;

                @include mixins.respond-to(max600) {
                    font-size: 12px;
                    font-weight: 600;
                    padding: 10px;
                    text-transform: uppercase;
                }
            }

            &:hover {
                background: none !important;
            }
        }

        a {
            border-right: solid 1px #ddd !important;
            display: block;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 15px;

            >i {
                font-size: 120%;
            }

            @include mixins.respond-to(max600) {
                font-size: 12px;
                font-weight: 600;
                padding: 10px;
                text-transform: uppercase;
            }
        }

        &:hover {
            background: #ddd;
        }
    }
}

.review-title-bar {
    a {
        display: block;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;

        @include mixins.respond-to(max600) {
            font-size: 12px;
            font-weight: 600;
            padding: 10px;
        }
    }
}

.product-story {
    display: block;
    width: 100%;
    overflow: hidden;
}

.product-story-info-text {
    // font-size: 12px;
    // letter-spacing: 0.25px;
    // line-height: 20px;
    // position: relative;
    // color: $text-color-liter;

    .subtitle {
        color: #888;
        font-size: 12px;
        padding: 0;
        text-transform: uppercase;
    }

    // .title {
    //     font-size: 24px;
    //     line-height: 1;
    //     margin: 5px 0 15px;
    //     color: #3a3a3a;
    //     min-height: 1px;
    //     padding: 0;
    //     position: relative;
    //     text-transform: uppercase;
    //     font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
    // }

    .desc {
        font-size: 12px;
        letter-spacing: 0.25px;
        line-height: 20px;
        min-height: 1px;
        position: relative;
        color: vars.$text-color-liter;
    }

    ul {
        padding-left: 10px;
        margin-top: 15px;

        li {
            margin-bottom: 5px;
        }
    }
}

.product-story {
    border-bottom: solid 1px #ddd;
    padding-bottom: 50px;
    padding-top: 50px;

    @include mixins.respond-to(xsOnly) {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    &.product-story-hasbg {
        padding: 200px 0;
        background-attachment: fixed;

        @include mixins.respond-to(xsOnly) {
            padding: 100px 0;
        }        

        .product-story-info-text {
            float: right;
            max-width: 80%;
            padding: 50px;
            width: 450px;
            @include mixins.crossbrowseropacity(white, 0.90);

            @include mixins.respond-to(xsOnly) {
                max-width: 80%;
                padding: 20px;
                width: 300px;
            }

            &__link {
                text-decoration: underline;
            }
        }
    }
}

.product-story-boxes {
    .product-story-info-text {
        padding: 50px;

        @include mixins.respond-to(xsOnly) {
            padding: 10px;
        }
    }
}

.product-story-info-box {
    padding: 50px 0;

    .product-story-info-text {
        max-width: 400px;
    }

    @include mixins.respond-to(vars.$xsOnly) {
        padding: 15px 0;
    }
}

// REVIEW
.section-review {
    padding: 50px 0;
    position: relative;
}

.productReviewTitleBAr {
    margin-bottom: 50px;

    .hero-section-title {
        i {
            display: block;
            margin-bottom: 10px;
        }
    }

    .rating {
        i {
            font-size: 18px
        }
    }

    .ratingInfo {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 10px;
        text-transform: uppercase;
    }

    .ratingNumber {
        padding: 0 15px 0 5px;
    }
}

.review-sortByBar {
    border-bottom: 1px solid vars.$line-color;
    border-top: 1px solid vars.$line-color;
    clear: both;
    display: block;
    font-weight: 600;
    height: auto;
    line-height: 20px;
    margin-bottom: 40px;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    float: left;

    @include mixins.respond-to(xsOnly) {
        padding: 10px 15px;
    }

    h4 {
        font-family: oswald;
        font-size: 13px;
        line-height: 34px;
    }
}

.review-item {
    border-bottom: 1px solid vars.$line-color;
    padding: 20px 0;
}

.review-item-user-profile {
    float: left;
    margin-right: 10px;

    .img-circle {
        max-width: 70px;
        border: 1px solid vars.$line-color;
    }
}

.reviewUserTitle {
    padding-bottom: 10px;
}

.user-name {
    padding-top: 15px;

    p {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 120px;
    }
}

.review-load-more {
    margin-top: 20px;
}

.section-similar-product {
    display: block;
    padding: 50px 0;
}

.sizeguide-table {
    font-size: 13px;
}

// PRODUCT DETAILS 5.1
.graybg {
    background: vars.$gray;
}

.nav-tabs {
    &.flat {
        border: 0;
        margin: 0;
        margin-bottom: 0;

        li {
            a {
                font-family: Source sans pro;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;

                @include mixins.respond-to(max600) {
                    font-size: 11px;
                    padding: 10px 7px;
                }

                @include mixins.respond-to(max400) {
                    border: 1px solid #ddd;
                }
            }
        }

        @include mixins.respond-to(xsOnly) {
            display: flex;
            flex-direction: row;
            margin: -1px -15px;
            flex-wrap: nowrap;
            align-items: center;
            justify-items: center;
            justify-content: center;
        }
    }
}

// PRODUCT DETAILS 5.3
.product-images-carousel-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    clear: both;

    .productMainImage {
        margin-left: auto;
        max-width: 80%;
        display: block;
        clear: both;

        @include mixins.respond-to(xsOnly) {
            margin: 0 auto 10px;
            max-width: 95%;
            display: block;
        }

        &.hasWhiteImg {
            background: #fff;
            border: 1px solid #cfcfcf;
        }        
    }

    .bx-wrapper {
        margin-bottom: 0;

        @include mixins.respond-to(vars.$mdOnly) {
            margin-bottom: 15px !important;
        }

        .bx-viewport {
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            border: medium none;
            box-shadow: none;
            left: 0;
        }
    }

    .product-view-thumb-wrapper {
        left: 30px;
        position: absolute;
        top: 0px;
        z-index: 10;
        width: 85px;

        @include mixins.respond-to(xsOnly) {
            left: auto;
            position: relative;
            top: auto;
            z-index: 10;
            width: 100%;
            margin: 0 auto 25px;
        }

        .bx-wrapper .bx-viewport {
            left: 0;
        }

        .product-view-thumb-nav {
            cursor: pointer;
            display: block;
            float: left;
            height: 35px;
            line-height: 35px;
            margin-top: 0;
            position: relative;
            text-align: center;
            width: 100%;
            z-index: 10;

            @include mixins.respond-to(xsOnly) {
                display: none;
            }

            i {
                font-size: 28px;
            }
        }

        ul {
            width: 55px;

            >li {
                cursor: pointer;
                height: 100px;
                border: 1px solid #cfcfcf;
                transition: border-color 0.8s ease 0s;
                width: 100%;
                background: vars.$gray;
                overflow: hidden;

                @include mixins.respond-to(xsOnly) {
                    height: 60px;
                    width: 70px;
                }

                >a {
                    display: block;
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        // ul
        &.hasWhiteImg {
            ul {

                >li {
                    background: #fff;
                }
            }
        }
    }

    @include mixins.respond-to(smOnly) {
        .product-view-thumb-wrapper {
            left: 10px;
            top: 0px;
            width: 50px;
        }

        .product-view-thumb-wrapper ul>li {
            height: 60px;
        }
    }
}

@include mixins.respond-to(xsOnly) {
    .review-load-more {
        .btn {
            margin-bottom: 10px;
        }
    }
}

// popup video
/**
   * Simple fade transition,
   */
.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

/* end */

// .product-story-info-box .product-story-info-text {
//     max-width: 100%;
// }

// .product-story-info-box .product-story-info-text .form-control-static {
//     padding-top: 0;
//     padding-bottom: 0;
// }

.productLooseColourSizes {
    margin-top: 20px;
}

.product-details-stock-status {
    cursor: help;
    border-bottom: 1px dashed #03417B;
    font-weight: 700;
}

ul.productStock {

    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;    

    >li {
        margin: vars.$spacing vars.$spacing 0 0;
        cursor: pointer;
        position: relative;
        border: 1px solid #ddd;
        width: 150px;
        padding: vars.$spacing;
        text-align: center;

        &.selected {
            border-color: vars.$color2;
        }

        .badge {
            background-color: #ec7063;
            position: absolute;
            top: -6px;
            right: -11px;
        }

        img {
            max-width: 124px;
        }
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

div.stockDetails {
    $headerHeight: 40px;
    $imgHeight: 300px;
    $imgWidth: 200px;
    display: none;
    position: relative;

    .productStockImage {
        h4 {
            line-height: $headerHeight;
        }

        @media screen and (min-width: 768px) {
            position: absolute;
            width: $imgWidth;
            top: vars.$spacing;
            left: vars.$spacing;
        }
    }

    @media screen and (max-width: 767px) {
        display: block;
    }

    .productStockContent {
        min-height: $imgHeight + $headerHeight + (vars.$spacing*2);
        padding: (vars.$spacing + $headerHeight) vars.$spacing vars.$spacing;
        margin: 0 0 vars.$spacing ($imgWidth + (vars.$spacing*2));

        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }
}

tr.even {
    background: vars.$altTableRow;
}

.stock {
    vertical-align: middle !important;
}

.stockInput {
    vertical-align: middle !important;

    >input[type=number] {
        min-width: 60px;
    }
}

.fullWidth {
    .product-view-thumb-wrapper {
        display: none;
    }

    .productMainImage {
        max-width: 100%;
    }
}

.product-price {
    font-size: inherit;

    .explanation {
        cursor: pointer;
    }
}

.product-view-thumb-wrapper {
    li {
        border: 0 !important;

        img {
            border: 1px solid #cfcfcf !important;
        }
    }
}

.entity-stock {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 5px 5px 10px 0px rgba(214, 214, 214, 1);
    box-shadow: 5px 5px 10px 0px rgba(214, 214, 214, 1);

    &.tooltip.in {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    .tooltip-inner {
        background: #ffffff;
        color: #454545;
        max-width: none;
        text-align: left;
    }
}

table.atpTable {
    td {
        border: none;
        font-size: 10px;
        padding: 0;
        vertical-align: top;

        &.left {
            padding-right: 5px;
        }
    }
}