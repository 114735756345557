@use "../abstracts/vars";

.parallax-section {
    width: 100%;
    display: block;
    clear: both;
    height: auto;

    &.section-hero-parallax {
        .lead, .lead-2 {
            color: vars.$color-white;
            padding: 2em;
        }
    }
}

.parallax-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    /* IE FIX */
}

.parallax-image.ismobile {
    background-attachment: scroll !important;
}

.parallax-image.isios,
.blog-intro.isios {
    background-size: 100% auto !important;
    background-attachment: scroll !important;
    background-position: center center;
}

.parallax-image.isandroid {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center;
}

.parallax-image.isios {
    background-color: #176396;
    background-repeat: no-repeat;
}

.parallax-content h2 {
    color: vars.$color-white;
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 auto;
    padding: 5px 10px;
    text-align: center;
}

.parallax-content h3 {
    color: #DDDDDD;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    margin: 20px;
    padding: 10px;
    text-align: center;
    background: rgba(81, 46, 7, 0.5);
}

.btn-discover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 3px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 14px 60px;
    text-decoration: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.parallaxOffset {
    position: absolute;
    margin-top: 0;
    top: vars.$headerTopHeight + vars.$navHeight;
    -webkit-transition: all 0.1s ease-out 0s;
    -moz-transition: all 0.1s ease-out 0s;
    -ms-transition: all 0.1s ease-out 0s;
    -o-transition: all 0.1s ease-out 0s;
    transition: all 0.1s ease-out 0s;

    .down {
        margin-top: 28px;
    }
}

.parallax-content {
    text-align: center;
    padding: 95px 0;

    .btn-discover {
        background: #2980b9;
    }

    .btn-discover:hover {
        background: #3498db;
    }
}

@media screen and (max-width: 767px) {
    .parallaximg.ismobile {
        margin-top: 0 !important;
    }

    .parallaxOffset {
        top: 87px;
    }

    .parallax-content {
        padding: 20px 0;
    }

    .parallax-content h2 {
        color: vars.$color-white;
        display: inline-block;
        font-size: 20px;
        line-height: 22px;
    }

    .parallax-content h3 {
        font-size: 16px;
        line-height: normal;
    }
}