table.app-table td.enabled,
table.app-table th.enabled,
table.app-table td.delete {
    text-align: center;
}

table.app-table i.enabled {
    color: green;
}

table.app-table i.disabled {
    color: red;
}

.api-sample-queries {
    h1 {
        margin-top: 20px;
        line-height: 18px;
        color: #1e4e79;
    }

    h2 {
        color: #2e75b5;
    }
}

.query-demo {
    padding: 10px;
    color: #333;
    background-color: rgba(149, 217, 177, 1);
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin: auto;

    &-title {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 10px;
    }
}