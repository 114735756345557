#feeds {
    .field-name {
        font-family: monospace;
        font-weight: 700;
        width: 33%;
    }

    .field-description {
        width: 67%;
    }

    .feed-url {
        font-size: 1.5em;
        text-align: center;
        font-family: monospace;
        white-space: pre-wrap;
        word-wrap: break-word;
        background-color: #f2f2f2;
        padding: 10px;
        width: auto;
        margin: auto;
    }

    .feed-url.hasFields {
        font-size: 0.8em;
    }

    .feeds-intro {
        margin-bottom: 20px;
    }
}