﻿@use "../../abstracts/vars";

.notLoggedIn .categoryProduct .product {
    padding-bottom: vars.$spacing;
}

.loggedIn .categoryProduct .product {
    padding-bottom: 35px + vars.$spacing;
}

.product {
    display: block;
    height: 100%;
    -ms-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-align: center;
    position: relative;

    &:hover {
        background: vars.$color-white;
    }
}

.categoryProduct {
    .image {
        padding: 0 12px;

        picture {
            display: block;
            margin: auto;

            @media screen and (min-width: 320px) {
                width: 134px;
                height: 134px;
            }

            @media screen and (min-width: 360px) {
                width: 154px;
                height: 154px;
            }

            @media screen and (min-width: 375px) {
                width: 162px;
                height: 162px;
            }

            @media screen and (min-width: 411px) {
                width: 180px;
                height: 180px;
            }

            @media screen and (min-width: 768px) {
                width: 200px;
                height: 200px;
            }

            @media screen and (min-width: 1024px) {
                width: 186px;
                height: 186px;
            }

            @media screen and (min-width: 1280px) {
                width: 236px;
                height: 236px;
            }

            @media screen and (min-width: 1440px) {
                width: 294px;
                height: 294px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 10px;

        .item.col-xs-6 {
            margin: 0;
            padding: 0;

            h4 {
                font-size: 14px;
            }
        }
    }

    .product {
        position: relative;
        padding-top: 40px;

        .price {
            position: absolute;
            bottom: 0;
            padding: vars.$spacing;
            font-size: 18px;
            font-weight: normal;
            color: vars.$text-color;

            @media screen and (max-width: 767px) {
                font-size: 15px;
            }

            &.price-old {
                left: 0;
                text-decoration: line-through;
            }

            &.price-now {
                right: 0;

                &.price-has-old-price {
                    color: vars.$color-red;
                }
            }
        }

        .product-code {
            font-weight: 700;
        }

        .product-name {
            text-transform: uppercase;
        }

        .description {
            min-height: 232px;

            h4 {
                margin-top: 20px;
            }
        }

        .product-panel-banner {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 12;
            margin-right: -7px;
            margin-top: 2px;

            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
        }

        .brand {
            position: absolute;
            top: 6px;
            left: 6px;
            z-index: 2;
        }
    }
}
