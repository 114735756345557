@use "../abstracts/vars";
@use "../abstracts/mixins";

.overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    hr {
        border: none;
        border-top: 1px solid vars.$gray-light2;
    }

    >div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        p {
            flex-grow: 1;
        }

        a {
            margin-top: 1em;
        }

        .block-title-3 {
            width: 100%;
        }
    }
}