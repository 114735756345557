@use "../abstracts/vars";
@use "../abstracts/mixins";

.footer {
    background: #f2f2f2;
    height: auto;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid vars.$gray-light;
    border-top: 1px solid vars.$gray-light;
}   

.footer-bottom {
    background: vars.$color-white;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    p.pull-left {
        padding-top: 6px;
    }
}

@media (max-width: 767px) {
    .footer-bottom {
        text-align: center;

        p.pull-left {
            float: none !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
}