@use "variables";

// Fixed Width Icons
// -------------------------
@use 'sass:math'; 

.#{variables.$fa-css-prefix}-fw {
  width: math.div(18em, 14);
  text-align: center;
}
