﻿@use "../../abstracts/vars";

/* Variables */
$transition: all 0.1s ease-out 0s;
$bullet-size: 10px;
$bullet-margin: 4px;

/* HOME PAGE SLIDER */
.banner {
    transition: $transition;
    max-height: 600px;

    .down {
        margin-top: 26px;
    }

    .swiper-wrapper {
        max-height: 220px;

        @media screen and (min-width: 768px) {
            max-height: 480px;
        }

        @media screen and (min-width: 1024px) {
            max-height: 600px;
        }

        @media screen and (min-width: 1600px) {
            max-height: 600px;
        }

        .swiper-slide {
            display: flex;
            align-items: stretch;
            justify-content: center;

            a,
            div {
                display: block;
                text-decoration: none;
            }
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            display: inline-block;
            border: 3px solid vars.$color-white;
            box-sizing: content-box;
            border-radius: 50%;
            margin: 0 $bullet-margin;
            width: $bullet-size;
            height: $bullet-size;
            text-align: center;
            text-indent: -999em;
            overflow: hidden;
            background: var(--swiper-pagination-bullet-inactive-color, transparent);
            opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);

            &.swiper-pagination-bullet-active,
            &:hover {
                opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
                border-color: vars.$color1;
            }
        }
    }

    &-boxes {
        margin-top: 80px;
    }

    .sliderInfo {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;

        .container {
            height: 100%;
            display: block;
        }
    }
}

#bannerPager {
    position: absolute;
    bottom: vars.$spacing;
    margin: 0 auto;
    z-index: 6;
    width: 100%;
    text-align: center;

    a {
        display: inline-block;
        border: 3px solid vars.$color-white;
        box-sizing: content-box;
        border-radius: 50%;
        margin: 0 $bullet-margin;
        width: $bullet-size;
        height: $bullet-size;
        text-align: center;
        text-indent: -999em;
        overflow: hidden;

        &.cycle-pager-active,
        &:hover {
            border-color: vars.$color1;
        }
    }
}

@media (max-width: 320px) {
    .banner {
        width: 100% !important;
        max-width: 320px !important;
        overflow: hidden !important;
    }
}

.slider-content,
.slider,
.slider-v2 {
    width: 100%;
    display: block;
    height: auto;
    overflow: hidden;
    position: relative;
}

.sliderImg {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.sliderText {
    display: block;
    margin-top: 15%;
    -webkit-transform: translateX(-120px);
    -moz-transform: translateX(-120px);
    -o-transform: translateX(-120px);
    -ms-transform: translateX(-120px);
    transform: translateX(-120px);
    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);
    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);
    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);
    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);
    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);
    opacity: 0;
    -ms-filter: "alpha(Opacity=0)";
    /* IE FIX */
    filter: alpha(opacity=0);

    &:nth-child(n) {
        filter: none;
    }

    .inner {
        padding: 20px;
    }

    h1 {
        font-size: 36px;
        font-weight: lighter;
    }

    &.dark {
        color: vars.$gray-light;

        h1 {
            color: vars.$color-white;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        }
    }
}

.slide-link {
    color: vars.$color-white;
    letter-spacing: -0.25px;
    font-size: 16px;
}

.slider-item {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &-img1, &-img2, &-img3 {
        background-size: cover;
    }

    &.cycle-slide-active {
        .sliderText {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
            -ms-filter: none;
            filter: none;
        }
    
        .transformRight {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
            -ms-filter: none;
            /* IE FIX */
            filter: none;
        }
    
        .transformLeft {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
            -ms-filter: none;
            filter: none;
        }
    
    }
}

.transformLeft {
    display: block;
    -webkit-transform: translateX(-120px);
    -moz-transform: translateX(-120px);
    -o-transform: translateX(-120px);
    -ms-transform: translateX(-120px);
    transform: translateX(-120px);
    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);
    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);
    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);
    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);
    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);
    opacity: 0;
    filter: alpha(opacity=0);
    /* IE FIX */
    -ms-filter: "alpha(Opacity=0)";
}

.transformRight {
    display: block;
    -webkit-transform: translateX(120px);
    -moz-transform: translateX(120px);
    -o-transform: translateX(120px);
    -ms-transform: translateX(120px);
    transform: translateX(120px);
    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);
    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);
    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);
    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);
    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);
    opacity: 0;
    filter: alpha(opacity=0);
    /* IE FIX */
    -ms-filter: "alpha(Opacity=0)";
}

/***  ------- Slider Style 1 Start ------- ***/

#pager2 {
    position: absolute;
    bottom: 10px;
    z-index: 100;
    width: 100%;
    z-index: 200;
    margin: 0 auto;
    padding: 0;
    text-align: center;

    span {
        background: rgba(0, 0, 0, 0) !important;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 16px;
        margin: 5px;
        text-indent: -9999px;
        width: 16px;

        &.cycle-pager-active {
            color: vars.$color-white;
        }
    }
}


.sliderControl {
    position: absolute;
    z-index: 200;
    top: 45%;
    display: inline-block;
    cursor: pointer;
    background: vars.$color-white;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #454545;
    padding: 0 5px;
}

.sliderControl {
    opacity: 1;

    &:hover {
        background: rgb(255, 255, 255);
        background: transparent \9;
        background: rgba(255, 255, 255, 0.6);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99ffffff, endColorstr=#99ffffff);
        /* IE FIX */
        zoom: 1;

        &:nth-child(n) {
            filter: none;
        }
    }
} 

.prevControl {
    left: 20px;
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);
    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);
    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);
    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);
    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);
    opacity: 0;
    -ms-filter: "alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.nextControl {
    right: 20px;
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -o-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);
    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);
    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);
    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);
    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);
    opacity: 0;
    -ms-filter: "alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.banner {
    &:hover .prevControl {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }

    &:hover .nextControl {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
}

.sliderText {
    &.white {
        background: rgb(255, 255, 255);
        background: transparent \9;
        background: rgba(255, 255, 255, 0.8);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff);
        /* IE FIX */
        zoom: 1;

        &:nth-child(n) {
            filter: none;
        }
    }

    h3.price {
        font-weight: lighter;
        font-size: 22px;
    }
}

.sliderTextFull {
    color: vars.$color-white;
    top: 50%;
    margin: 0 !important;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    
    .opacity0 {
        opacity: 0 !important;
    }
    
    .inner.dark {
        display: block;
        margin: 0 auto;
        padding: 20px 10px;
        width: auto;
        background: rgb(0, 0, 0);
        background: transparent \9;
        background: rgba(0, 0, 0, 0.8);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#cc000000, endColorstr=#cc000000);
        /* IE FIX */
        zoom: 1;

        &:nth-child(n) {
            filter: none;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
    }
}

.topAnima {
    opacity: 0;
}

.cycle-slide-active .topAnima {
    opacity: 1;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-delay: 0.3s;
    visibility: visible !important;
}

.cycle-slide-active .sliderTextFull .bottomAnima {
    opacity: 1 !important;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-delay: 0.4s;
    animation-duration: 1s;
    visibility: visible !important;
}

.blankstyle h1 {
    display: block;
    font-size: 36px;
    font-weight: bold;
    height: auto;
    letter-spacing: -1px;
    line-height: 36px;
    opacity: 1;
    padding: 0;
    text-transform: uppercase;
}

.color-white {
    h1, p, & {
        color: vars.$color-white;
    }
}

.color-black {
    h1, p, & {
        color: vars.$color-black;
    }
}

/***  ------- Slider Style 2 Pager ------- ***/

#pager {
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;
    z-index: 200;
    margin: 0 auto;
    padding: 0;

    a {
        color: vars.$color-white;
        display: block;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        float: left;
        font-family: Oswald, Helvetica Neue;
        font-size: 15px;
        font-weight: normal;
        list-style: none outside none;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        width: 33.333334%;
        background: rgba(0, 0, 0, 0.5);

        &.cycle-pager-active {
            color: vars.$color-white;
        }
    }
}