@use "../../abstracts/utilities";
@use "../../abstracts/vars";


.list-view .product .add-fav {
    right: 40px;
    top: 8px;
}

.list-view .property-brand, .list-view .property-packing, .list-view .property-colour, .list-view .property-colours, .property-besteleenheid {
    display: none;
}

.item.list-view .item h4 {
    max-height: none;
}

.item.list-view .grid-description {
    display: none;
}

.list-description {
    display: none;
}

.item.list-view .list-description {
    display: block;
}

.item {
    &.list-view {        
        height: auto !important;
        width: 100% !important;
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;

        .image {
            display: block;            
            width: 310px;            
            position: relative;
            text-align: center;
            -ms-transition: all 0.5s ease 0s;
            -moz-transition: all 0.5s ease 0s;
            -webkit-transition: all 0.5s ease 0s;
            -o-transition: all 0.5s ease 0s;
            transition: all 0.5s ease 0s;
            padding-right: 20px;
            background: vars.$color-white;

            a {
                display: block;
                max-height: 100%;
                height: 100%;
                max-height: 100% !important;
            }

            img {
                width: auto !important;
            }
        }

        h4 {
            height: auto;

            a {
                font-weight: 600;
            }
        }

        .description {
            text-align: right;
            margin: 0 12px 0 310px;

            p {
                text-align: right;
                max-height: 200px;
                min-height: 10px;
            }
        }

        .price {
            font-size: 22px;
            text-align: right;
        }

        .action-control {
            text-align: right;
        }
    }   
}
