@use "../../abstracts/vars";

.personEven {
    background-color: #f5f5f5;
    padding: 0.4em 0;
}

.personOdd {
    background-color: #eee;
    padding: 0.4em 0;
}

.client-registration-person {
    padding: 10px 10px;
    margin: 10px -10px;
}

select.form-control {
    margin-bottom: 10px;
}

#client-registration {
    &-success {
        display: none;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
    }

    &-step-2 {
        display: none;
    }

    #client-registration #btnClientRegistrationSubmit1 {
        margin-top: 15px;
    }

    .explanation {
        font-weight: 400;
        font-style: italic;
    }
}

label.required:after {
    content: "*";
    color: vars.$color-red;
    margin-left: 5px;
}
 span.error {
    color: vars.$color-red;
    margin-left: 5px;
 }

.company-profile label.checkbox {
    font-weight: 400;
}
