@use "../../abstracts/vars";

#product-images-main {

    .youtube-player {
        height: 270px;
        width: 100%;

        @media (min-width: 768px) {
            height: 500px;
        }
    }

    li {
        display: none;
        background-color: vars.$color-white;
        text-align: center;
        padding: 10px;
        vertical-align: middle;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        
        &.selected {
            display: flex;

            @media all and (-ms-high-contrast:none) {
                display: block;
            }
        }

        a {
            padding-top: 25px;
        }

        img {
            margin: auto;
        }
    }
}

#product-images-thumbnails {
    padding-bottom: 10px;

    .fa-youtube-play {
        position: absolute;
        top: 11px;
        left: 36px;

        &:hover {
            color: vars.$color-red;
        }
    }

    li {
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        background-color: vars.$color-white;
        display: inline-block;
        border: 1px solid vars.$line-color;
        cursor: pointer;
        width: 118px;
        height: 65px;

        &.YouTube {
            position: relative;
        }

        &.selected {
            border: 1px solid vars.$color-blue-darker;
        }
    }
}

li .brand__top-left {
    position: absolute;
    top: 10px;
    left: 25px;

    @media only screen and (max-width: 768px) {
        width: 90px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

#media-download {
    display: none; 
   
    #jpeg-info {
        display: none;
        margin-top: 20px;

        h2 {
            font-size: 18px;
            padding: 0;
        }
    }
}



.simplebar-scrollbar::before {
    background-color: vars.$text-color-liter;
}

.scroll-bar {
    white-space: nowrap;
}

// .scroll-bar::-webkit-scrollbar {
//     width: 8px;
//     height: 8px;
//     background-color: $text-color-extra-liter;
// }

// .scroll-bar::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     //border-radius: 8px;
//     background-color: $text-color-liter;
//     //padding: 2px;
//     border: 4px solid $gray;
// }

// .scroll-bar::-webkit-scrollbar-thumb {
//  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
//     width: 6px;
//     //border: 3px solid $text-color-extra-darker;
//     background-color: $text-color-darker;

// }

// .scroll-bar::-webkit-scrollbar-thumb:hover {
//     background-color: $text-color-extra-darker;
// }

// .scroll-bar::-webkit-scrollbar-button {
//     display: none;
// }

// .scroll-bar::-webkit-scrollbar-corner {
//     display: none;
// }