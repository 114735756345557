//@import "styles";

@use "abstracts/vars";
@use "abstracts/mixins";
@use "abstracts/utilities";

@use "base/custom";

@use "themes/home-v7";

// @import "components/datetimepicker/bootstrap-datetimepicker-build";
@use "components/header/header";
@use "components/header/header_desktop";
@use "components/footer";
@use "components/typeahead";

@use "components/buttons";
@use "components/parallax";
@use "components/menu/megamenu";
@use "components/menu/account-menu";
@use "components/menu/ordersteps";
@use "components/menu/filters";

@use "partials/basket/basket";
@use "partials/carousel/brands";
@use "partials/cart/nav";
@use "partials/cart/sidebar";
@use "partials/home/banner";
@use "partials/products/details";
@use "partials/products/listing";
@use "partials/products/listview";
@use "partials/account/feeds";
@use "partials/account/registration";
@use "partials/account/takeover";
@use "partials/account/api-apps";

@use "pages/search";
@use "pages/vacancies";
@use "pages/checkout";

.error {
    ul {
        background: vars.$error;
        padding: vars.$spacing;
    }
}

.scLooseFrameZone {
    display: inline;
}

.modal {
    .modal-header {
        margin-bottom: 0;

        h4 {
            padding-bottom: 0;
        }

        .close {
            margin-top: 0;
            color: vars.$color-white;
            opacity: 1;
        }
    }
}

/* Absolute Center Spinner */
#ajaxloader {
    display: none;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
#ajaxloader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
#ajaxloader:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.rich-text, .default-lists {
    ul, ol {
        list-style: unset;
        padding: 0 0 0 40px;
        margin: 0 0 20px 0;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}

.rich-text {
    a {
        text-decoration: underline;
        font-weight: 700;
    }
}

#vgCheckout2 {
    #chkAcceptTerms-explanation {
        a {
            text-decoration: underline;
        }
    }
}

.homepage-warning {
    color: #a94442 !important;
    text-align: center;
    display: block;
}

.delivery-delay {
    color: #a94442 !important;
    text-align: center;
    display: block;
}

@media screen and (max-width: 767px) {
    .globalPadding {
        padding: 10px 0;
    }
}

.client-takeover-name {
    font-weight: 700;
    color: vars.$color-red;
}

@media screen and (max-width: 767px) {
    .spotlight-image {
        margin-bottom: 10px;
    }
}

.input-b2f9d0d6 {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    display: none;
}

.notifications {
    margin: 15px 0;
}