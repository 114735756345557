@use "../abstracts/vars";
@use "../abstracts/mixins";

.tt-menu,
.gist {
    text-align: left;
}

.typeahead,
.tt-query,
.tt-hint {
    width: 396px;
    height: 30px;
    padding: 8px 12px;
    font-size: 24px;
    line-height: 30px;
    border: 2px solid vars.$gray-light2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    outline: none;
}

.typeahead {
    background-color: vars.$color-white;
}

.typeahead:focus {
    border: 2px solid vars.$color-blue-light-100;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    margin: 12px 0;
    padding: 8px 0;
    right: 0;
    background-color: vars.$color-white;
    border: 1px solid vars.$gray-light2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    text-align: left;
}

@media screen and (max-width: 767px) {
    .tt-menu {
        left: 0;
        width: 400px;
    }
}

@media screen and (min-width: 768px) {
    .tt-menu {
        width: 600px;
    }
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    font-weight: 400;
    font-family: monospace;    
}

.tt-suggestion:hover {
    cursor: pointer;
    color: vars.$color-white;
    background-color: vars.$color-blue-light-100;
}

.tt-suggestion.tt-cursor {
    color: vars.$color-white;
    background-color: vars.$color-blue-light-100;

}

.tt-suggestion p {
    margin: 0;
}

.gist {
    font-size: 14px;
}