@use "../../abstracts/vars";
@use "../../abstracts/mixins";

.cart-sidebar {
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.5);

    .cart-pin-trigger {
        display: inline-flex;
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        appearance: none;

        i {
            color: vars.$color-white;
            opacity: 0.6;
            font-style: italic;
        }

        &.is-pinned {
            i {
                opacity: 1;
                font-style: normal;
            }
        }
    }

    &__pinned {
        position: fixed;
        top: 0px;
        right: 0px;
    }

    .cart-sidebar-header {
        h3 {
            padding-left: 10px;
            display: inline-flex;
        }
    }
}

.cart-pin-trigger {
    visibility: hidden;
    display: none;
}

@include mixins.respond-to(desktopOnly) {
   .cart-pin-trigger {
        visibility: visible;
        display: block;
    }
}

.miniCartProduct, .miniCartProduct a, .miniCartProduct p, .miniCartProduct .size, .miniCartProduct .price, .dropdown-header {
    color: vars.$color-black !important;
}
