﻿@use "../../abstracts/vars";

.brands-carousel {
 
  .swiper-slide {
    //height: calc((100% - 30px) / 2);
    padding: 0 vars.$spacing;
    width: auto;

    &__centered {
      @media screen and (max-width: 390px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .img {
      object-fit: cover;
    }

    figure {
      position: relative;
      overflow: hidden;
    }

    figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(20%);
      display: flex;
      align-items: baseline;
      justify-content: center;
      padding: 20px;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.4s;
    }
  }
}