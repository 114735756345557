@use "../../abstracts/vars";

$dropdown-border: 1px solid vars.$color1;

.userMenu {
    ul.ssi-subMenu {
        display: none;
        position: absolute;       

        &:hover {
            display: block;
        }

        > li {
            line-height: 24px !important;
            white-space: nowrap;

            > a {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 11px !important;
                color: vars.$color1 !important;
                background: vars.$color-white !important;
                line-height: 24px !important;
                padding: 0 10px !important;
                transition: all 0.2s ease 0s;

                &:hover {
                    padding-left: 15px !important;
                    padding-right: 5px !important;
                }
            }
        }
    }

    &.ssi-topMenu {

        ul.ssi-subMenu {
            top: 32px;  
            right: 0;
        }
    }

    &.stuckMenu {
        position: relative;

        li,
        a {
            line-height: vars.$navHeight  !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        ul.ssi-subMenu {
            top: vars.$navHeight - 1;
            right: 0;
        }
    }

    .ssi-dropdown {
        position: relative;

        &:hover {

            >.ssi-subMenu,
            +.ssi-subMenu {
                display: block;
            }

            > a {
                background: vars.$color-white;
                position: relative;
                padding: 0 9px;
                margin-top: -1px;                
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .userMenu.ssi-topMenu {
        a {
            line-height: 27px !important;
            padding: 3px 10px 0 !important;

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}