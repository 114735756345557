@use "../../abstracts/vars";

.nav-stacked {
    &.tree {
        > li a:hover {
            color: vars.$color-blue;
            font-weight: bold;
        }
    }
}

.nav-stacked > li {
    border-bottom: solid 1px #eee;

    &.active {
        background: none;
        color: vars.$text-color;
        font-weight: bold;

        a {
            color: inherit;
            font-weight: inherit;
        }
    }
}
