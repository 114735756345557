﻿/*
    #1595
    Add flexbox to the filter menu so all numbered badges are aligned to the right    
*/
.nav-stacked.tree {
    li {
        display: flex;

        &.open-tree {
            flex-direction: column;
        }
    }

    li a {
        align-items: flex-start;
        flex-direction: row;
        flex-grow: 1;
    }

    .badge {
        align-self: center;
    }
}

/*
    #1601
    Input readonly for masked file inputs
 */
input[readonly] {
    background-color: white !important;
    cursor: text !important;
}
