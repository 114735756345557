@use "../../abstracts/vars";
@use "../../abstracts/mixins";

.overly-mask,
.menu-overly-mask {
    background-color: vars.$color-black;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    visibility: hidden;
    width: 100%;
    z-index: 15;
}

.overly-mask.is-visible {
    cursor: pointer;
    opacity: 0.25;
    visibility: visible;
}

.menu-overly-mask.is-visible {
    cursor: pointer;
    opacity: 0.55;
    visibility: visible;
}

.cart-sidebar-content,
.menu-sidebar-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
}

.menu-sidebar-content {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.cart-sidebar {
    background-color: vars.$color-white;
    bottom: 0;
    color: #333;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -webkit-transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    right: -450px;
    width: 340px;
    z-index: 10000;
    top: 0;

    .cart-sidebar-header {
        background: vars.$text-color-darker;
        padding: 15px;
        height: 50px;

        h3 {
            color: vars.$color-white;
            font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: unset;
            letter-spacing: 1px;
            margin-bottom: 0;
            padding-bottom: 0;
            text-transform: uppercase;
        }

        .cart-close-trigger {
            position: absolute;
            right: 15px;
            font-size: 30px;
            color: vars.$gray-light;

            .close {
                color: vars.$color-white;
                float: right;
                font-size: 28px;
                font-weight: normal;
                line-height: 1;
                opacity: 1;
                text-shadow: none;

                &:hover {
                    color: vars.$color-white;
                }
            }
        }
    }
}

.cart-sidebar {

    &.is-visible {
        right: 0px;
    }

    .cartMenu {
        height: 100%;

        table tr:last-child {
            border-bottom: none;
        }

        table tr td {
            padding: 10px 0;

            &.delete {
                padding-left: 10px;
                padding-right: 10px;

                button.close {
                    float: left;
                }
            }
        }

        .scroll-pane {
            height: 90%;
            height: calc(100% - 95px - 51px);

            // .mCSB_container {
            //     margin-right: 12px;
            // }
        }
    }

    .subtotal {
        padding-bottom: 0;
        padding-right: 5px;
    }

    .cart-panel-footer {
        border-top: 1px solid vars.$gray-light;

        .col-sm-6 {
            padding: 10px 5px;
        }
    }

    .miniCartProduct {
        -webkit-transition: all 0.25s linear 0s;
        -moz-transition: all 0.25s linear 0s;
        -o-transition: all 0.25s linear 0s;
        -ms-transition: all 0.25s linear 0s;
        transition: all 0.25s linear 0s;

        &:hover {
            background: #f9f9f9;
        }
    }
}

.menu-sidebar {
    background-color: vars.$color-white;
    bottom: 0;
    color: #333;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    left: -300px;
    -o-transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -webkit-transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    width: 300px;
    z-index: 10000;
    top: 0;

    &.is-visible {
        left: 0;
        right: 0;
    }

    .cart-sidebar-content {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
    }
}

.menu-sidebar-content {
    .inner {
        padding: 48px;
    }

    .menu-sidebar-header h3 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 32px;
        font-weight: 700;
    }
}

.nav-tree-light {
    margin-top: 30px;

    li > a {
        text-transform: uppercase;
    }

    li a {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
        border: medium none;
        color: #333;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 2px;
        padding: 16px 0;
        text-decoration: none;

        &:hover {
            opacity: .75;
        }
    }

    li ul {
        background: none !important;

        a {
            font-size: 12px;
            padding: 10px 0 10px 15px;
        }
    }
}

// @media screen and (max-width: 767px) {
@include mixins.respond-to(xsOnly) {
    .navbar-cart {
        overflow: hidden;
    }

    // .cartMenu {
    //     float: none;
    // }

    // .cart-sidebar {
    //     width: 100%;
    // }
}

@include mixins.respond-to(max480) {
    .cart-sidebar {
        width: 100%;
    }
}