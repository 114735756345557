﻿@use "../../abstracts/vars";
@use "../../abstracts/utilities";

.megamenu .nav,
.megamenu .dropup,
.megamenu .dropdown,
.megamenu .collapse {
    position: static;
}

.megamenu .navbar-inner,
.megamenu .container {
    position: relative;
}

.megamenu .dropdown-menu {
    left: auto;

    > li {
        display: block;
    }
}

.megamenu .nav.pull-right .dropdown-menu {
    right: 0;
}

.megamenu .megamenu-content {
    padding: 10px 10px;
    *zoom: 1;
}

.megamenu .megamenu-content:before,
.megamenu .megamenu-content:after {
    display: table;
    content: "";
    line-height: 0;
}

.megamenu .megamenu-content:after {
    clear: both;
}

.megamenu.navbar .nav > li >.dropdown-menu:after,
.megamenu.navbar .nav > li >.dropdown-menu:before {
    display: none;
}

.megamenu-content img {
    max-width: 100%;
}

.megamenu-content ul li {
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 10px;
    padding-top: 10px;

    a {
        font-size: 15px;
    }

    &:hover {
        background-color: #f9f9f9;
    }
}

@media (max-width: 969px) {
    .megamenu .dropdown.megamenu-fullwidth .dropdown-menu {
        width: auto;
    }

    .megamenu .megamenu-content {
        padding-left: 0;
        padding-right: 0;
    }

    .megamenu .dropdown-menu>li>ul {
        display: block;
    }
}

@media (min-width: 979px) {
    .megamenu-content ul li a {
        -webkit-transition: all 0.2s ease 0s;
        -moz-transition: all 0.2s ease 0s;
        -ms-transition: all 0.2s ease 0s;
        -o-transition: all 0.2s ease 0s;
        transition: all 0.2s ease 0s;
        display: block;
    }

    .megamenu-content ul li a:hover {
        padding-left: 5px;
    }

    .megamenu-content ul li.productPopItem a:hover,
    .megamenu-content ul li a.newProductMenuBlock:hover {
        padding-left: 0;
    }
}

@media (min-width: 768px) {

    .ProductDetailsList.megamenu-content ul {
        padding-left: 5px;
        padding-right: 5px;
    }

    .ProductDetailsList.megamenu-content ul li a {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media screen and (max-width: 767px) {

    .megamenu-content ul li a,
    .megamenu-content ul li p,
    .megamenu-content ul li,
    .navbar-nav .dropdown a,
    .navbar-nav .dropdown {
        color: vars.$color-white;

        > a {
            &:focus,
            &:hover {	
                background-color: vars.$color-blue-dark;
            }
        }
    }

    .megamenu-content ul li {
        border-color: rgba(255, 255, 255, 0.2);
    }
}

.container.static .megamenu-content.container {
    padding-left: 0;
    padding-right: 0;
}